<template>
    <modal :id="id" title="Reject Blog Post">
        <template #default>
            <p>
                This will
                <strong>reject</strong>
                the Blog Post
                <strong>{{ blogPost.headline }}</strong>
                .
            </p>
            <v-spacer />
            <a-form ref="form" :warn-dirty="false">
                <a-text-input
                    v-model="reason"
                    label="Message For User"
                    rules="required|max:1024"
                    rows="1"
                    textarea
                    auto-grow
                />
            </a-form>
        </template>
        <template #actions>
            <v-btn text :block="$vuetify.breakpoint.smAndDown" @click="close">
                Cancel
            </v-btn>
            <v-spacer />
            <v-btn
                color="primary"
                :block="$vuetify.breakpoint.smAndDown"
                :disabled="!isValid"
                @click="reject"
            >
                Confirm
            </v-btn>
        </template>
    </modal>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { InjectReactive } from '@/utils/decorators';
import { reduceErrors } from '@/utils/helpers';

import { Modal } from '@/components/Modal';

import { AForm } from '@/components/AForm';
import { ATextInput } from '@/components/AForm/Inputs/ATextInput';

import type { BlogPost } from '@/types/BlogPost';

@Component({
    components: {
        Modal,
        AForm,
        ATextInput
    }
})
export default class BlogPostReject extends Vue {
    $refs!: {
        form: InstanceType<typeof AForm>;
    };

    id = 'blog-post-reject';

    reason = '';

    get isValid() {
        return this.reason && this.$refs.form?.isValidated
            ? this.$refs.form?.isValid
            : false;
    }

    @InjectReactive({
        from: 'blogPost',
        default() {
            return {
                id: 0
            };
        }
    })
    blogPost!: BlogPost;

    async reject() {
        return this.$http
            .post(`/blog_posts/reject/${this.blogPost.id}`, {
                approval_message: this.reason
            })
            .then(({ data }) => {
                if (!data.meta?.success) {
                    const errors = new Set<string>();

                    reduceErrors(data.meta?.errors, errors);

                    this.$store.dispatch(
                        'notification/error',
                        [...errors].join('<br/>')
                    );
                } else {
                    this.$emit('confirm');

                    this.close();
                }
            })
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch(
                        'notification/error',
                        'Unable to update Blog Post. Please try again later or contact support.'
                    );
                }
            });
    }

    close() {
        this.$store.dispatch('modal/close', this.id);
    }
}
</script>
