<template>
    <v-row v-if="loading" class="a-list-row ma-0 mb-2 white pt-3 pb-3">
        <v-col cols="1" class="pa-0 pl-3 ma-0">
            <v-avatar rounded color="main-background">
                <v-icon color="grey lighten-2" large>image</v-icon>
            </v-avatar>
        </v-col>
        <v-col cols="6" class="pl-2 pr-0 pt-4 ma-auto">
            <v-skeleton-loader
                type="text"
                :width="randomBetween(25, 75) + '%'"
            />
        </v-col>
        <v-col cols="2" class="pl-2 pr-0 pt-4 ma-auto">
            <v-skeleton-loader
                type="text"
                :width="randomBetween(35, 55) + '%'"
            />
        </v-col>
        <v-col cols="2" class="pl-2 pr-0 pt-4 ma-auto">
            <v-skeleton-loader
                type="text"
                :width="randomBetween(20, 35) + '%'"
            />
        </v-col>
        <v-col cols="1" class="pl-2 pr-0 pt-4 py-0 ma-auto" />
    </v-row>
    <v-hover v-else v-slot="{ hover }">
        <v-row
            class="a-list-row ma-0 mb-2"
            :class="{
                white: !hover && !isActive,
                'concrete-solid': hover || isActive,
                'elevation-2': hover || isActive,
                'px-2': $vuetify.breakpoint.mdAndDown,
                'with-action': hasAmp
            }"
            @click="onRowAction"
        >
            <v-col cols="1" :class="{ 'px-0': $vuetify.breakpoint.mdAndDown }">
                <v-avatar rounded size="48" color="main-background">
                    <v-img v-if="logo" :src="logo" />
                    <v-icon v-else color="grey lighten-2" large>image</v-icon>
                </v-avatar>
            </v-col>
            <v-col
                cols="6"
                class="pl-2 pr-0 ma-auto"
                :class="{
                    'cell-disabled': !hasHeadline
                }"
            >
                <text-truncated :text="headline" />
            </v-col>
            <v-col cols="2" class="pl-2 pr-0 ma-auto">
                {{ blogPost.announcement?.category.name }}
            </v-col>
            <v-col cols="2" class="pl-2 pr-0 ma-auto">
                <text-truncated
                    v-if="canShowPublishDate"
                    :text="formatDate(blogPost.publish_time)"
                    :tooltip="formatDateTime(Number(blogPost.publish_time))"
                />
            </v-col>
            <v-col cols="1" class="pl-2 pr-0 py-0 ma-auto" align="right">
                <blog-post-row-actions
                    @open="markRowActive"
                    @action="onBlogPostAction"
                />
            </v-col>
        </v-row>
    </v-hover>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import {
    formatDate,
    formatDateTime,
    isTextSelectionEvent,
    randomBetween
} from '@/utils/helpers';

import { TextTruncated } from '@/components/TextTruncated';
import BlogPostRowActions from './BlogPostRowActions.vue';

import type { AdminBlogPostAction, BlogPost } from '@/types/BlogPost';
import { InjectReactive } from '@/utils/decorators';

const BlogPostRowProps = Vue.extend({
    name: 'BlogPostRow',
    props: {
        blogPost: {
            type: Object as PropType<Partial<BlogPost>>,
            default(): BlogPost | null {
                return null;
            }
        },
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        BlogPostRowActions,
        TextTruncated
    }
})
export default class BlogPostRow extends BlogPostRowProps {
    isActive = false;

    get logo() {
        if (this.blogPost) {
            const media = this.blogPost.media_resources;

            if (media?.length) {
                return media[0].media_file.relative_filename;
            }
        }
        return false;
    }

    get headline() {
        return this.blogPost?.headline || 'No Headline Available';
    }

    get hasHeadline() {
        return this.blogPost?.headline;
    }

    get canShowPublishDate() {
        return this.blogPost.publish_time;
    }

    get hasAmp() {
        return Boolean(this.blogPost.announcement_id);
    }

    @InjectReactive({
        from: 'onAction',
        default() {
            return () => void 0;
        }
    })
    onAction!: (action: AdminBlogPostAction, video: Partial<BlogPost>) => void;

    formatDate = formatDate;
    formatDateTime = formatDateTime;
    randomBetween = randomBetween;

    markRowActive(isActive: boolean) {
        this.isActive = isActive;
    }

    onBlogPostAction(action: AdminBlogPostAction) {
        return this.onAction(action, this.blogPost);
    }

    onRowAction(e: Event) {
        if (!isTextSelectionEvent(e) && this.hasAmp) {
            this.openBlogPost();
        }
    }

    openBlogPost() {
        Object.assign(
            document.createElementNS('http://www.w3.org/1999/xhtml', 'a'),
            {
                target: '_blank',
                href: `/app/announcements/review/${this.blogPost.announcement_id}/blog-post`
            }
        ).click();
    }
}
</script>

<style lang="scss">
@import '@/components/AList/AList.scss';
</style>
