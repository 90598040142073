<template>
    <modal :id="id" title="Preview Blog Post" :persistent="false">
        <template #default>
            <h2 class="font-weight-medium text-justify">
                {{ blogPost.headline || '&#160;' }}
            </h2>
            <v-divider class="my-4" />
            <!-- eslint-disable vue/no-v-html -->
            <h3
                class="font-weight-regular text-justify"
                v-html="blogPost.summary || '&#160;'"
            ></h3>
            <!-- eslint-enable vue/no-v-html -->
            <v-divider class="my-4" />
            <media-preview-list
                :media-resources="blogPost.media_resources"
                :allow-add="false"
                :allow-delete="false"
                :allow-edit="false"
            />
            <v-divider class="my-4" />
            <!-- eslint-disable vue/no-v-html -->
            <h3
                class="font-weight-regular text-justify"
                v-html="blogPost.content || '&#160;'"
            ></h3>
            <!-- eslint-enable vue/no-v-html -->
            <v-divider class="my-4" />
        </template>
        <template #actions>
            <v-btn text :block="$vuetify.breakpoint.smAndDown" @click="close">
                Cancel
            </v-btn>
            <v-spacer />
            <v-btn
                class="error--text"
                :block="$vuetify.breakpoint.smAndDown"
                @click="onBlogPostAction('reject')"
            >
                Reject
            </v-btn>
            <v-btn
                color="primary"
                :block="$vuetify.breakpoint.smAndDown"
                @click="onBlogPostAction('approve')"
            >
                Approve
            </v-btn>
        </template>
    </modal>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { InjectReactive } from '@/utils/decorators';

import { Modal } from '@/components/Modal';
import { MediaPreviewList } from '@/components/Media';

import type { AdminBlogPostAction, BlogPost } from '@/types/BlogPost';

@Component({
    components: {
        Modal,
        MediaPreviewList
    }
})
export default class BlogPostPreview extends Vue {
    id = 'blog-post-preview';

    @InjectReactive({
        from: 'blogPost',
        default() {
            return {
                id: 0
            };
        }
    })
    blogPost!: BlogPost;

    @InjectReactive({
        from: 'onAction',
        default() {
            return () => void 0;
        }
    })
    onAction!: (action: AdminBlogPostAction, video: Partial<BlogPost>) => void;

    onBlogPostAction(action: AdminBlogPostAction) {
        return this.onAction(action, this.blogPost);
    }

    close() {
        this.$store.dispatch('modal/close', this.id);
    }
}
</script>
