<template>
    <v-container>
        <v-row class="my-2 px-3">
            <div class="text-h6 text-md-h5 font-weight-bold">
                MSN Review queue
            </div>
        </v-row>

        <blog-posts-list
            :blog-posts="blogPosts"
            :loading="isLoading"
            :updating="isUpdating"
            class="px-0"
        />

        <paging
            :total="total"
            :default-per-page="perPage"
            :loading="isUpdating"
            class="main-background px-0"
        />

        <blog-post-action-dialogs @update="update" />
    </v-container>
</template>

<script lang="ts">
import { ListPaged } from '@/mixins';
import { ProvideReactive } from '@/utils/decorators';
import Component, { mixins } from 'vue-class-component';

import { Paging } from '@/components/Paging';

import BlogPostsList from '@/components/BlogPostsList/BlogPostsList.vue';

import BlogPostActionDialogs from './BlogPostActionDialogs';

import type { BlogPost, AdminBlogPostAction } from '@/types/BlogPost';

@Component({
    components: {
        BlogPostsList,
        Paging,
        BlogPostActionDialogs
    }
})
export default class Approval extends mixins(ListPaged) {
    endpoint = '/blog_posts/approval';

    blogPosts: BlogPost[] = [];

    onData(data: { length: number; blogPosts: BlogPost[] }) {
        const { blogPosts, length } = data;

        this.blogPosts = blogPosts;

        this.total = length;
    }

    @ProvideReactive()
    blogPost: Partial<BlogPost> | null = null;

    @ProvideReactive()
    onAction(action: AdminBlogPostAction, blogPost: BlogPost) {
        this.blogPost = blogPost;

        this.$store.dispatch('modal/open', `blog-post-${action}`);
    }
}
</script>
