<template>
    <v-container v-if="isBusy && $vuetify.breakpoint.mdAndUp">
        <blog-posts-list-header
            v-if="$vuetify.breakpoint.mdAndUp"
            :loading="loading"
        />
        <blog-post-row
            v-for="blogPost in blogPosts.length || 10"
            :key="'blog-post-row-' + blogPost"
            loading
        />
    </v-container>
    <v-container v-else-if="isBusy">
        <v-skeleton-loader
            v-for="i in 5"
            :key="i"
            type="card-heading, list-item-avatar-three-line"
            class="mb-6"
        />
    </v-container>
    <v-container v-else-if="hasData && $vuetify.breakpoint.mdAndUp">
        <blog-posts-list-header />
        <blog-post-row
            v-for="(blogPost, index) in blogPosts"
            :key="'blog-post-row-' + blogPost.id + '-' + index"
            :blog-post="blogPost"
        />
    </v-container>
    <v-container v-else-if="hasData" class="px-0">
        <blog-post-card
            v-for="(blogPost, index) in blogPosts"
            :key="'blog-post-card-' + blogPost.id + '-' + index"
            :blog-post="blogPost"
            class="mb-2"
            flat
            style="cursor: pointer"
        />
    </v-container>
    <a-message-bar v-else>No Blog Posts found to approve</a-message-bar>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import { AMessageBar } from '@/components/AMessageBar';

import BlogPostsListHeader from './BlogPostsListHeader.vue';
import BlogPostRow from './BlogPostRow.vue';
import BlogPostCard from './BlogPostCard.vue';

import type { BlogPost } from '@/types/BlogPost';

const BlogPostsListProps = Vue.extend({
    name: 'BlogPostsList',
    props: {
        blogPosts: {
            type: Array as PropType<Partial<BlogPost>[]>,
            default(): BlogPost[] {
                return [];
            }
        },
        loading: {
            type: Boolean,
            default() {
                return true;
            }
        },
        updating: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        AMessageBar,
        BlogPostsListHeader,
        BlogPostRow,
        BlogPostCard
    }
})
export default class BlogPostsList extends BlogPostsListProps {
    get hasData() {
        return Boolean(this.blogPosts.length);
    }

    get isBusy() {
        return this.loading || this.updating;
    }
}
</script>
