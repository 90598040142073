<template>
    <v-menu
        transition="slide-y-transition"
        :close-on-click="true"
        :close-on-content-click="true"
        :offset-y="true"
        left
        @input="open"
    >
        <template #activator="{ on, attrs }">
            <v-btn fab plain v-bind="attrs" v-on="on">
                <v-icon>ellipsis-v</v-icon>
            </v-btn>
        </template>
        <v-list>
            <v-list-item key="edit" @click="onAction('approve')">
                <v-list-item-icon class="mr-2">
                    <v-icon small>check</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Approve</v-list-item-title>
            </v-list-item>
            <v-list-item key="delete" @click="onAction('reject')">
                <v-list-item-icon class="mr-2">
                    <v-icon small color="error">xmark</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="error--text">
                    Reject
                </v-list-item-title>
            </v-list-item>
            <v-list-item key="review" @click="onAction('preview')">
                <v-list-item-icon class="mr-2">
                    <v-icon small>eye</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Preview</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class BlogPostRowActions extends Vue {
    onAction(action: string) {
        this.$emit('action', action);
    }

    open(isOpen: boolean) {
        this.$emit('open', isOpen);
    }
}
</script>
