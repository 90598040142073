import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.loading)?_c(VCard,{staticClass:"amp-card amp-card--top-border unapproved pt-3 pl-2",staticStyle:{"padding-bottom":"1px"}},[_c(VSkeletonLoader,{staticStyle:{"margin-top":"-2px"},attrs:{"type":"heading","width":_vm.randomBetween(25, 55) + '%'}}),_c(VListItem,{staticClass:"pa-0 pl-2",attrs:{"three-line":""}},[_c(VListItemAvatar,{attrs:{"rounded":"","size":"100","color":"main-background"}},[_c(VIcon,{attrs:{"color":"grey lighten-2","large":""}},[_vm._v("image")])],1),_c(VListItemContent,{staticClass:"mt-2"},[_c(VSkeletonLoader,{staticClass:"pl-4",attrs:{"type":"heading","width":_vm.randomBetween(5, 15) + '%'}}),_c(VSkeletonLoader,{attrs:{"type":"list-item-two-line","width":_vm.randomBetween(35, 55) + '%'}})],1)],1)],1):_c(VCard,{staticClass:"amp-card amp-card--top-border"},[_c(VListItem,{attrs:{"three-line":""}},[_c(VListItemAvatar,{attrs:{"rounded":"","size":"48","color":"main-background"}},[(_vm.logo)?_c(VImg,{attrs:{"src":_vm.logo}}):_c(VIcon,{attrs:{"color":"grey lighten-2","large":""}},[_vm._v("image")])],1),_c(VListItemContent,{staticClass:"mt-2"},[_c(VListItemTitle,{staticClass:"headline text-body-1 align-self-start"},[_c('text-truncated',{attrs:{"text":_vm.headline}})],1),_c(VListItemSubtitle,{staticClass:"align-self-end text-right amp-card--date mt-2"},[(_vm.canShowPublishDate)?_c('text-truncated',{attrs:{"text":_vm.formatDate(_vm.blogPost.publish_time),"tooltip":_vm.formatDateTime(Number(_vm.blogPost.publish_time))}}):_vm._e()],1)],1),_c(VListItemAction,[_c('blog-post-row-actions',{on:{"action":_vm.onBlogPostAction}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }