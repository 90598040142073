<template>
    <v-card
        v-if="loading"
        class="amp-card amp-card--top-border unapproved pt-3 pl-2"
        style="padding-bottom: 1px"
    >
        <v-skeleton-loader
            type="heading"
            style="margin-top: -2px"
            :width="randomBetween(25, 55) + '%'"
        />
        <v-list-item three-line class="pa-0 pl-2">
            <v-list-item-avatar rounded size="100" color="main-background">
                <v-icon color="grey lighten-2" large>image</v-icon>
            </v-list-item-avatar>
            <v-list-item-content class="mt-2">
                <v-skeleton-loader
                    class="pl-4"
                    type="heading"
                    :width="randomBetween(5, 15) + '%'"
                />
                <v-skeleton-loader
                    type="list-item-two-line"
                    :width="randomBetween(35, 55) + '%'"
                />
            </v-list-item-content>
        </v-list-item>
    </v-card>
    <v-card v-else class="amp-card amp-card--top-border">
        <v-list-item three-line>
            <v-list-item-avatar rounded size="48" color="main-background">
                <v-img v-if="logo" :src="logo" />
                <v-icon v-else color="grey lighten-2" large>image</v-icon>
            </v-list-item-avatar>

            <v-list-item-content class="mt-2">
                <v-list-item-title
                    class="headline text-body-1 align-self-start"
                >
                    <text-truncated :text="headline" />
                </v-list-item-title>

                <v-list-item-subtitle
                    class="align-self-end text-right amp-card--date mt-2"
                >
                    <text-truncated
                        v-if="canShowPublishDate"
                        :text="formatDate(blogPost.publish_time)"
                        :tooltip="formatDateTime(Number(blogPost.publish_time))"
                    />
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
                <blog-post-row-actions @action="onBlogPostAction" />
            </v-list-item-action>
        </v-list-item>
    </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import { formatDate, formatDateTime, randomBetween } from '@/utils/helpers';
import { InjectReactive } from '@/utils/decorators';

import { TextTruncated } from '@/components/TextTruncated';
import BlogPostRowActions from './BlogPostRowActions.vue';

import type { AdminBlogPostAction, BlogPost } from '@/types/BlogPost';

const BlogPostCardProps = Vue.extend({
    name: 'BlogPostCard',
    props: {
        blogPost: {
            type: Object as PropType<Partial<BlogPost>>,
            default(): BlogPost | null {
                return null;
            }
        },
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        TextTruncated,
        BlogPostRowActions
    }
})
export default class BlogPostCard extends BlogPostCardProps {
    get logo() {
        if (this.blogPost) {
            const media = this.blogPost.media_resources;

            if (media?.length) {
                return media[0].media_file.relative_filename;
            }
        }
        return false;
    }

    get headline() {
        return this.blogPost?.headline || 'No Headline Available';
    }

    get canShowPublishDate() {
        return this.blogPost.publish_time;
    }

    formatDate = formatDate;
    formatDateTime = formatDateTime;
    randomBetween = randomBetween;

    @InjectReactive({
        from: 'onAction',
        default() {
            return () => void 0;
        }
    })
    onAction!: (
        action: AdminBlogPostAction,
        blogPost: Partial<BlogPost>
    ) => void;

    onBlogPostAction(action: AdminBlogPostAction) {
        return this.onAction(action, this.blogPost);
    }
}
</script>

<style lang="scss" scoped>
@import '@/components/ACard/ACard.scss';
</style>
