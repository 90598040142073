<template>
    <span>
        <blog-post-approve @confirm="confirm" />
        <blog-post-reject @confirm="confirm" />
        <blog-post-preview />
    </span>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import BlogPostApprove from './BlogPostApprove.vue';
import BlogPostReject from './BlogPostReject.vue';
import BlogPostPreview from './BlogPostPreview.vue';

@Component({
    components: {
        BlogPostApprove,
        BlogPostReject,
        BlogPostPreview
    }
})
export default class BlogPostActionDialogs extends Vue {
    confirm() {
        // close Preview Dialog (if open)
        this.$store.dispatch('modal/close', 'blog-post-preview');

        this.$store.dispatch('notification/success', 'Blog Post updated');

        this.update();
    }

    update() {
        // ask parent to reload
        this.$emit('update', true);
    }
}
</script>
